class Util {

  static isoToDate = (isoString) => new Date(Date.parse(isoString))

  static getDayOfWeekName(d) {
    // if (typeof d == 'string') {
    //   d = Date.parse(d);
    // }
    // let formatter = new Intl.DateTimeFormat('de-DE', {weekday: 'long'})
    // return formatter.formatToParts(d).find((e) => e.type === 'weekday').value
    return d.toLocaleDateString('de-DE', {weekday: 'long'});
  }
  static getShortDate(d) {
    return d.toLocaleDateString('de-DE', {day: 'numeric', month: 'numeric'});

  }
}

export default Util

