import React, { Component } from 'react'
import { BrowserRouter, Route } from "react-router-dom";
import './App.scss';
import DailySpecialMain from './DailySpecialMain';
import MenuMain from './MenuMain';
import Gallery from './Gallery';

class App extends Component {
  constructor() {
    super();
    this.state = { offline: false };
  }

  onRequestFail() {
    this.setState({ offline: true })
  }
  onRequestSuccess() {
    this.setState({ offline: false })
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <div className={'App-offline-marker' + (this.state.offline ? ' active' : '')}></div>
          <Route exact path="/" render={
            () => <DailySpecialMain onRequestFail={this.onRequestFail.bind(this)} onRequestSuccess={this.onRequestSuccess.bind(this)} />
          } />
          <Route exact path="/menu/:display_name" render={
            (props) => <MenuMain displayName={props.match.params.display_name} onRequestFail={this.onRequestFail.bind(this)} onRequestSuccess={this.onRequestSuccess.bind(this)} />
          } />
          <Route exact path="/gallery/:display_name" render={
            (props) => <Gallery displayName={props.match.params.display_name} onRequestFail={this.onRequestFail.bind(this)} onRequestSuccess={this.onRequestSuccess.bind(this)} />
          } />
          <Route exact path="/daily-internal" render={
            () => <DailySpecialMain hideLegal={true} onRequestFail={this.onRequestFail.bind(this)} onRequestSuccess={this.onRequestSuccess.bind(this)} />
          } />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
