import React, { Component } from 'react';
import Util from './Util';
import DailySpecial from './DailySpecial';
import './DailySpecialMain.scss';
import logo from './dnh-logo.svg';
import ErpApiFetcher from './ErpApiFetcher';

class DailySpecialMain extends Component {
  constructor() {
    super();
    this.state = {
      dailySpecials: [],
      loaded: false
    }
  }
  componentDidMount() {
    const url = 'https://dnh.picosoft.de/pico-erp/api/get-meal-daily-specials';
    // const url = 'http://localhost/pico-erp/api/get-meal-daily-specials';
    this.apiFetcher = new ErpApiFetcher(5 * 60, this.onDataUpdate.bind(this), url, this.props.onRequestFail, this.props.onRequestSuccess);
    this.apiFetcher.start();
    document.title = "Tagesessen | DNH-Imbiss";
  }

  onDataUpdate(data) {
    if (data == null || data.length === 0) {
      return;
    }
    this.setState({ dailySpecials: data, loaded: true })
  }
  renderLoaded() {
    let todayDate = new Date();
    let today = todayDate.toISOString().substring(0, 10);
    let todayMeal = this.state.dailySpecials.find(m => m.date_iso === today);
    // if (todayMeal == null) {
      // todayMeal = this.state.dailySpecials[this.state.dailySpecials.length - 1];
      // today = todayMeal.date_iso;
      // todayDate = new Date(today);
    // }
    document.querySelector('meta[name="description"]').content="Die aktuellen Tagesgerichte für den DNH-Imbiss in der Carl-Spaeter-Straße 2P im Industriegebiet Koblenz";
    return (
      <div>
        <a href='https://www.dnh.ag/gastro' target='_blank' rel='noopener noreferrer' arial-label='DNH-Imbiss'><img src={logo} className="App-logo" alt="logo" /></a>
        <div className="DailySpecialMain-title">{Util.getDayOfWeekName(todayDate)}</div>
        {!this.props.hideLegal && <div className="DailySpecialMain-legal">
          <div><a href="/imprint.html">Impressum</a> </div>
          <div><a href="/privacy.html">Datenschutz</a> </div>
        </div>}
        {null != todayMeal && <div className="DailySpecialMain-special-today">
          <DailySpecial meal={todayMeal} hideDay={true} showAdditives={true} highlighted={true}/>
        </div>}
        <div className="DailySpecialMain-specials">
          {this.state.dailySpecials.filter(m => m.date_iso !== today).map(m =>
            <DailySpecial meal={m} key={m.date_iso} />
          )
          }
        </div>
      </div>
    );
  }
  renderLoading() {
    return <div className="DailySpecialMain-title">Lade Daten...</div>
  }
  render() {
    return <div className="DailySpecialMain">
      {this.state.loaded ? this.renderLoaded() : this.renderLoading()}
    </div>
  }
}

export default DailySpecialMain;
