class ErpApiFetcher {

  constructor(intervalSeconds, callback, url, requestFailListener, requestSuccessListener) {
    this.callback = callback;
    this.intervalSeconds = intervalSeconds;
    this.url = url;
    this.requestFailListener = requestFailListener;
    this.requestSuccessListener = requestSuccessListener;
    this.cacheStorageKey = 'api_data_' + this.url;
  }

  start() {
    // load from web storage if available
    this.loadCacheData();
    this.updateData();
    window.setInterval(() => this.updateData(), this.intervalSeconds * 1000)
  }

  updateData() {
    this.xhr = new XMLHttpRequest();
    this.xhr.addEventListener("load", () => this.onRequestLoad());
    this.xhr.addEventListener("error", (e) => {
      console.error("Error while sending request to url %s:",  this.url);
      this.notifyRequestFailListener();
    });
    this.xhr.open("GET", this.url);
    this.xhr.setRequestHeader("accept", "application/json");
    this.xhr.send();
  }

  notifyRequestFailListener() {
    if (this.requestFailListener != null) {
      this.requestFailListener();
    }
  }
  notifyRequestSuccessListener() {
    if (this.requestSuccessListener != null) {
      this.requestSuccessListener();
    }
  }

  onRequestLoad() {
    let responseText = this.xhr.responseText;
    if (this.xhr.status !== 200) {
      console.error("Received bad response code %d from url %s with response:\n%s", this.xhr.status, this.url, responseText);
      this.notifyRequestFailListener();
      return;
    }
    let json;
    try {
      json = JSON.parse(responseText);
    } catch (error) {
      console.error("Error while parsing response form url %s:\n%s", this.url, responseText)
      console.error(error);
      return;
    }
    localStorage.setItem(this.cacheStorageKey, responseText);
    this.notifyRequestSuccessListener();
    this.callback(json);
  }

  loadCacheData() {
    let cacheData = localStorage.getItem(this.cacheStorageKey);
    if (cacheData === null) {
      return;
    }
    let json;
    try {
      json = JSON.parse(cacheData);
    } catch (error) {
      console.error(error);
      return;
    }
    console.log("Serving cached data for " + this.url);
    this.callback(json);
  }


}

export default ErpApiFetcher;
