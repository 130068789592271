import React, { Component } from 'react';
import ErpApiFetcher from './ErpApiFetcher';
import './MenuMain.css';

class MenuMain extends Component {

  constructor() {
    super();
    this.state = { meals: [], loaded: false };
  }

  componentDidMount() {
    let url = "https://dnh.picosoft.de/pico-erp/api/get-meal-categories-for-display/display_name/" + this.props.displayName;
    // let url = "https://wsrb.picotronic.de/pico-erp/api/get-meal-categories-for-display/display_name/" + this.props.displayName;
    this.apiFetcher = new ErpApiFetcher(5 * 60, this.onDataUpdate.bind(this), url, this.props.onRequestFail, this.props.onRequestSuccess);
    this.apiFetcher.start();
    document.title = "Speisekarte Seite " + this.props.displayName.replace(/[^\d]+/, '') + " | DNH-Imbiss";
  }

  onDataUpdate(jsonData) {
    let meals = {};

    new Set(jsonData.map(e => e.category_name)).forEach(e => meals[e] = []);
    jsonData.forEach(e => meals[e.category_name].push(e));

    this.setState({ meals, loaded: true });
  }

  render() {
    return (
      <div className="MenuMain">
        {this.state.loaded ? (
          <div className="MenuMain-container">
            {Object.keys(this.state.meals).map((category, i) =>
              <div key={i} className="MenuMain-block">
                <div className="MenuMain-category">{category}</div>
                {this.state.meals[category].map((meal, j) =>
                  <div key={i + '.' + j} className="MenuMain-item">
                    <div className="MenuMain-item-body">
                      <div className="MenuMain-image">
                        {meal.photo_url != null && <img src={meal.photo_url} alt={meal.caption} />}
                      </div>
                      <div className="MenuMain-item-left">
                        <div className="MenuMain-caption">{meal.caption}</div>
                        <div className="MenuMain-annotation">{meal.annotation}</div>
                      </div>
                      <div className="MenuMain-item-right">
                        <div className="MenuMain-price">{meal.price > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(meal.price) : ''}</div>
                        <div className="MenuMain-ingredients">
                          {meal.additives && <div>{meal.additives}</div>}
                          {meal.allergens && <div>{meal.allergens}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
            <div>Lade Daten...</div>
          )}
      </div>
    );
  }
}

export default MenuMain;
