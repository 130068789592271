import React, { Component } from 'react';
import ErpApiFetcher from './ErpApiFetcher';
import './Gallery.scss';

class Gallery extends Component {

  constructor() {
    super();
    this.state = { meals: [], loaded: false, activeIndex: 0 };
  }

  componentDidMount() {
    let url = "https://dnh.picosoft.de/pico-erp/api/get-meal-categories-for-display/display_name/" + this.props.displayName;
    // let url = "https://wsrb.picotronic.de/pico-erp/api/get-meal-categories-for-display/display_name/" + this.props.displayName;
    this.apiFetcher = new ErpApiFetcher(5 * 60, this.onDataUpdate.bind(this), url, this.props.onRequestFail, this.props.onRequestSuccess);
    this.apiFetcher.start();
    setInterval(() => this.showNext(), 10 * 1000);
    document.title = "Anzeigen | DNH-Imbiss";
  }

  showNext() {
    let nextIndex = this.state.activeIndex + 1;
    if (nextIndex >= this.state.meals.length) {
      nextIndex = 0;
    }
    this.setState({activeIndex: nextIndex});
  }

  onDataUpdate(jsonData) {
    this.setState({ meals: jsonData.filter(meal => meal.photo_url != null), loaded: true });
  }

  render() {
    return (
      <div className="Gallery">
        {this.state.loaded ? (
          <div className="Gallery-container">
            {this.state.meals.map((meal, i) =>
              <div key={i} className={"Gallery-image" + (this.state.activeIndex === i ? ' active' : '')}>
                <img src={meal.photo_url_unscaled} alt={meal.caption} />
              </div>
            )}
          </div>
        ) : (
            <div>Lade Daten...</div>
          )}
      </div>
    );
  }
}

export default Gallery;
