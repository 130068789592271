import React from 'react';
import * as classNames from 'classnames';
import './DailySpecial.scss';
import Util from './Util';

class DailySpecial extends React.Component {
  date = Util.isoToDate(this.props.meal.date_iso);
  isHighlighted() {
    return this.props.highlighted === true;
  }
  isPast() {
    return this.date < new Date().setUTCHours(0, 0, 0, 0);
  }
  getClassNames() {
    return classNames('DailySpecial', { 'past': this.isPast() }, { 'highlighted': this.isHighlighted() })
  }

  render() {
    return <div className={this.getClassNames()}>
      {!this.props.hideDay && <div className="DailySpecial-header">
        <div className="DailySpecial-dow">{Util.getDayOfWeekName(this.date)}</div>
        {/* <div className="DailySpecial-date">{Util.getShortDate(this.date)}</div> */}
      </div>}
      <div className="DailySpecial-body">
        <div className="DailySpecial-photo">
          <div className="DailySpecial-photo-container">
            <img src={this.props.meal.photo_url} alt={this.props.meal.caption} />
          </div>
        </div>
        <div className="DailySpecial-text">
          <div className="DailySpecial-caption">{this.props.meal.caption}</div>
          <div className="DailySpecial-annotation">{this.props.meal.annotation}</div>
          <div className="DailySpecial-price">{this.props.meal.price_fmt != null ? this.props.meal.price_fmt.replace(/ .*/, '') : ''}</div>
          {this.props.showAdditives && <div className="DailySpecial-ingredients">
            {this.props.meal.additives && <div>Zusatzstoffe: {this.props.meal.additives}</div>}
            {this.props.meal.allergens && <div>Allergene: {this.props.meal.allergens}</div>}
          </div>}
        </div>
      </div>
    </div>
  }
}

export default DailySpecial;
